import { useContext, useEffect, useState } from 'react';
import {
  CommentAnalysisTag,
  CommentsAnalysis,
} from '../../__generated__/graphql';
import { Strong, Text, TextLink } from '../catalyst/text';
import { Subheading } from '../catalyst/heading';
import { Badge } from '../catalyst/badge';
import {
  ChevronDownIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';
import { Button } from '../catalyst/button';
import { ActionType, logAction } from '@kalecard/common';
import { UserContext } from '../../providers/UserProvider';

export default function CommentsAnalysisFeed({
  commentsAnalysis,
}: {
  commentsAnalysis: CommentsAnalysis[];
}) {
  return (
    <div className="space-y-3 divide-y pb-6">
      {commentsAnalysis.map((analysis, index) => (
        <CommentAnalysisComponent commentsAnalysis={analysis} index={index}/>
      ))}
    </div>
  );
}

function CommentAnalysisComponent({
  commentsAnalysis,
  index
}: {
  commentsAnalysis: CommentsAnalysis;
  index: number
}) {
  const { brandId, user } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  const formattedDateHeader = (date: string) => {
    return `Week of ${new Date(Number(date)).toLocaleDateString('en-us')}`;
  };

  useEffect(() => {
    logAction(ActionType.CLICK, "CommentsAnalysisComponent", {
      action_name: "toggle_comments_analysis",
      comments_analysis_id: commentsAnalysis.id,
      is_open: isOpen,
      comments_analysis_index: index,
      brand_id: brandId,
      brand_user_uid: user.uid
    })
  }, [isOpen]);

  return (
    <div
      key={commentsAnalysis.id}
      className="space-y-4 pt-3"
    >
      <div
        className="flex items-center space-x-2 rounded-md bg-gray-100 p-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Button
          outline
          onClick={() => setIsOpen(!isOpen)}
          className="hover:cursor-pointer"
        >
          {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </Button>
        <Subheading>{formattedDateHeader(commentsAnalysis.date)}</Subheading>
      </div>
      <div className={`${isOpen ? '' : 'hidden'} space-y-4`}>
        {/* Summary */}
        <div className="space-y-2 pr-6">
          <Strong>Brand sentiment</Strong>
          <Text>{commentsAnalysis.summary}</Text>
        </div>

        {/* Tags */}
        <CommentsAnalysisTags commentsAnalysis={commentsAnalysis} />
      </div>
    </div>
  );
}

function CommentsAnalysisTags({
  commentsAnalysis
} : {
  commentsAnalysis: CommentsAnalysis
}) {
  const { brandId, user } = useContext(UserContext);

  const [selectedTag, setSelectedTag] = useState<CommentAnalysisTag>(
    commentsAnalysis.tags?.[0]
  );

  useEffect(() => {
    logAction(ActionType.CLICK, "CommentsAnalysisTags", {
      action_name: "select_comments_analysis_tag",
      comments_analysis_id: commentsAnalysis.id,
      selected_tag: selectedTag.name,
      is_positive: selectedTag.isPositive
    })
  }, [selectedTag]);

  return (
    <div className="space-y-4">
      {commentsAnalysis.tags?.length > 0 && (
        <div className="flex gap-4">
          {commentsAnalysis.tags?.map((tag) => (
            <Badge
              key={tag.name}
              color={tag.isPositive ? 'green' : 'red'}
              className={`hover:cursor-pointer hover:underline capitalize ${
                selectedTag?.name === tag.name ? 'font-bold underline' : ''
              }`}
              onClick={() => setSelectedTag(tag)}
            >
              {tag.name}
            </Badge>
          ))}
        </div>
      )}

      {selectedTag && (
        <div className="space-y-2 rounded-md border p-4">
          {selectedTag.comments?.map((comment) => (
            <div key={comment.text}>
              <Text className="italic">
                "{comment.text}"
                {comment.postUrl && (
                  <TextLink
                    className="pl-2 font-semibold not-italic text-blue-500"
                    href={comment.postUrl}
                    target="_blank"
                    onClick={() => logAction(ActionType.CLICK, "CommentsAnalysisTags", {
                      action_name: "view_comment_post",
                      comments_analysis_id: commentsAnalysis.id,
                      selected_tag: selectedTag.name,
                      comment: comment.text,
                      post_url: comment.postUrl,
                      brand_id: brandId,
                      brand_user_uid: user.uid
                    })}
                  >
                    View post.
                  </TextLink>
                )}
              </Text>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}