import { useApolloClient } from '@apollo/client';

import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { publishableKey } from '../../utils/stripe';
import {
  InitiatePhotoPurchaseCheckoutQuery,
  InitiatePhotoPurchaseCheckoutQueryVariables,
} from '../../__generated__/graphql';
import { INITIATE_PHOTO_PURCHASE_CHECKOUT } from '../../graphql/queries';

const stripePromise = loadStripe(publishableKey);

export default function PhotoPayment({
  brandId,
  photoIds,
  handleCheckout,
}: {
  brandId: string;
  photoIds: string[];
  handleCheckout: (brandId: string, photoIds: string[]) => void;
}) {
  const client = useApolloClient();

  const fetchClientSecret = useCallback((): Promise<string> => {
    return client
      .query<
        InitiatePhotoPurchaseCheckoutQuery,
        InitiatePhotoPurchaseCheckoutQueryVariables
      >({
        query: INITIATE_PHOTO_PURCHASE_CHECKOUT,
        variables: { brandId, photoIds },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.initiatePhotoPurchaseCheckout.clientSecret;
      })
      .catch((error) => {
        console.error('Error fetching checkout session:', error);
        throw error;
      });
  }, [client, brandId, photoIds]);

  const options = { fetchClientSecret };

  const onCheckoutComplete = () => {
    console.log('Checkout complete');
    setTimeout(() => {
      handleCheckout(brandId, photoIds);
    }, 2000);
  };

  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{ ...options, onComplete: onCheckoutComplete }}
    >
      <EmbeddedCheckout className="m-4 w-full" />
    </EmbeddedCheckoutProvider>
  );
}
