import { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';

export default function LoadingPage() {
  const [progress, setProgress] = useState(0);

  // Set up an interval and increase the progress bar every 1 second
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        return Math.min(oldProgress + 1, 100);
      });
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <LoadingBar
        color="#0C490D"
        height={4}
        progress={progress}
      />
      <div className="flex items-center pl-4 pt-5">
        <img
          className="h-14 w-auto animate-pulse"
          src="https://storage.googleapis.com/kale-public/kale-brands/kale-lockup.svg"
          alt="Kale Dashboard"
        />
      </div>
    </>
  );
}
